import { ECustomCell, type ITableColumn } from "@/models/table.model";
import type { IUser } from "@/models/user.model";
import { formatDate } from "@/utils/table-format.util";

export const allUsersColumnsMap: Record<string, ITableColumn> = {
  user: {
    name: "user",
    label: "User",
    field: (row: IUser) => row.username,
    sortable: true,
    align: "left",
    display: true,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row: IUser) => row.isLocal,
    sortable: true,
    align: "left",
    display: true,
    format: (isLocal) => (isLocal === null ? "-" : isLocal ? "Local" : "SSO"),
  },
  lastLogin: {
    name: "lastLogin",
    label: "Last login",
    field: (row: IUser) => row.lastLogin,
    sortable: true,
    align: "left",
    display: true,
    format: (lastLogin) => formatDate(lastLogin),
  },
  accessRules: {
    name: "accessRules",
    label: "Access rule(s)",
    field: () => "View",
    sortable: false,
    align: "left",
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: IUser) => row.createdBy,
    sortable: true,
    align: "left",
    display: true,
    format: (createdBy) => createdBy || "-",
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: IUser) => row.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (creationTime) => formatDate(creationTime),
  },
  lastUpdated: {
    name: "lastUpdated",
    label: "Last updated",
    field: (row: IUser) => row.updatedAt,
    sortable: true,
    align: "left",
    display: true,
    format: (updatedAt) => formatDate(updatedAt) || "-",
  },
};

export const allUserColumns: Array<ITableColumn> = [
  allUsersColumnsMap.user,
  allUsersColumnsMap.type,
  allUsersColumnsMap.lastLogin,
  allUsersColumnsMap.accessRules,
  allUsersColumnsMap.createdBy,
  allUsersColumnsMap.creationTime,
  allUsersColumnsMap.lastUpdated,
];

export const usersIndexColumns: Array<ITableColumn> = [
  { ...allUsersColumnsMap.user, display: true, mandatory: true },
  { ...allUsersColumnsMap.type, display: true },
  { ...allUsersColumnsMap.lastLogin, display: true },
  { ...allUsersColumnsMap.accessRules, display: true },
  { ...allUsersColumnsMap.createdBy, display: true },
  { ...allUsersColumnsMap.creationTime, display: true },
  { ...allUsersColumnsMap.lastUpdated, display: true },
];
