import type { IUIVolume } from "@/models/data-source.model";
import {
  ClaimInfoVolumeModeEnum,
  Scope,
  type PVCAsset,
  type PVCCreationRequest,
} from "@/swagger-models/assets-service-client";
import { makeId } from "../common.util";
import type { IScopeCredentialsList } from "@/models/credential.model";
import { ScopeType } from "@/swagger-models/authorization-client";
import type { IUIAssetCreationMeta } from "@/models/global.model";

const DEFAULT_CLAIM_INFO_SIZE = "1G";

export const dataSourceUtil = {
  mapPvcsToUiVolumes,
  convertVolumeToPvc,
  getEmptyVolume,
  getRandomClaimName,
  getListScopeByMeta,
};

function mapPvcsToUiVolumes(pvcs: Array<PVCAsset>): Array<IUIVolume> {
  return pvcs.map((volume: PVCAsset) => {
    return {
      claimName: "claim-" + makeId(10),
      path: volume.spec.path || "",
      ephemeral: volume.spec.ephemeral || false,
      claimInfo: volume.spec.claimInfo || {
        size: DEFAULT_CLAIM_INFO_SIZE,
        storageClassName: "",
        accessModes: {},
        volumeMode: ClaimInfoVolumeModeEnum.Filesystem,
      },
    };
  });
}

export interface IScopeInfo {
  scope: Scope;
  projectId?: number;
  departmentId?: string;
}

function convertVolumeToPvc(volume: IUIVolume, scopeInfo: IScopeInfo): PVCCreationRequest {
  return {
    meta: {
      name: volume.claimName,
      scope: scopeInfo.scope,
      projectId: scopeInfo.projectId,
      departmentId: scopeInfo.departmentId,
      autoDelete: true,
    },
    spec: {
      path: volume.path,
      claimName: volume.claimName,
      ephemeral: volume.ephemeral,
      claimInfo: {
        accessModes: volume.claimInfo.accessModes,
        storageClass: volume.claimInfo.storageClass,
        size: volume.claimInfo.size,
        volumeMode: volume.claimInfo.volumeMode,
      },
    },
  };
}

function getRandomClaimName(prefix: string): string {
  return `${prefix}-` + makeId(5); // Generating a random name for the claim because the name input is hidden from the user
}

function getEmptyVolume(): IUIVolume {
  return {
    claimName: getRandomClaimName("claim"),
    path: "",
    ephemeral: false,
    claimInfo: {
      size: DEFAULT_CLAIM_INFO_SIZE,
      storageClass: null,
      accessModes: {},
      volumeMode: ClaimInfoVolumeModeEnum.Filesystem,
    },
  };
}

function getListScopeByMeta(assetMeta: IUIAssetCreationMeta): IScopeCredentialsList {
  const listScopeFilter: IScopeCredentialsList = {};
  if (assetMeta.scope === ScopeType.Tenant) {
    listScopeFilter.scope = ScopeType.Tenant;
  } else if (assetMeta.scope === ScopeType.Cluster && assetMeta.clusterId) {
    listScopeFilter.clusterId = assetMeta.clusterId;
  } else if (assetMeta.scope === ScopeType.Department && assetMeta.departmentId) {
    listScopeFilter.departmentId = assetMeta.departmentId;
  } else if (assetMeta.scope === ScopeType.Project && assetMeta.projectId) {
    listScopeFilter.projectId = assetMeta.projectId;
  }
  return listScopeFilter;
}
